.signatureCanvas {
  border-radius: 4px;
	border: 1px solid #BFC4C8;
  width: 100%;
  height: 250px;
  min-width: 600px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}