body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	height: 100%;
	touch-action: none; /* disables touch gestures (eg. pinch to zoom) on the touch scren */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 1367px) {
	body {
		overflow: scroll;
		touch-action: auto;
	}

	input {
		font-size: 16px;
	}
}

@font-face {
	font-family: 'HelveticaNeue';
	src: local('HelveticaNeueThin'), url('./fonts/HelveticaNeueThin.ttf') format('truetype');
	font-weight: 200;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: local('HelveticaNeueLight'), url('./fonts/HelveticaNeueLight.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: local('HelveticaNeueMedium'), url('./fonts/HelveticaNeueMedium.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'HelveticaNeueMedium';
	src: local('HelveticaNeueBold'), url('./fonts/HelveticaNeueBold.ttf') format('truetype');
	font-weight: 500;
}