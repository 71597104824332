* {
	margin: 0;
	padding: 0;
}
.main {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	position: relative;
	margin: 0 auto;
	margin-top: 8px;
	margin-bottom: 10px;
	overflow: hidden;
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	-o-animation: spin 2s linear infinite;
	-ms-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
	}
}

.inside {
	width: 100%;
	height: 50%;
	position: absolute;
	margin-top: 50%;
	background: linear-gradient(90deg, rgb(220, 220, 220), rgb(255, 255, 255));
}
.inside:before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	margin-top: -50%;
	background: linear-gradient(90deg, rgb(220, 220, 220), rgb(127, 222, 100));

}
.inside:after {
	content: '';
	width: 80%;
	height: 160%;
	position: absolute;
	margin-top: -40%;
	margin-left: 10%;
	background: inherit;
	border-radius: 50%;
}
